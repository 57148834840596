<template>
    <div class="container-fluid">
        <div class="col-lg-5 col-md-5 text-left">
            <b-link to="/">
                <img class="logo-img" alt="sdv logo" fluid-grow src="../../src/assets/logo_sdv.png" />
            </b-link>
        </div>
        <div class="col-lg-2 col-md-1 text-right">
        </div>
        <div class="col-lg-3 col-md-4 text-right">
            <b-link class="nav-link text-md-right text-center" to="/Calendly">
                Prendre rendez-vous
            </b-link>
        </div>
        <div class="col-lg-2 col-md-2 text-md-left text-sm-center">
            <b-dropdown id="dropdown-1" right text="MENU" variant=outline-light>
            <b-dropdown-item to="/">ACCUEIL</b-dropdown-item>
            <b-dropdown-item to="/Quisuisje">QUI SUIS-JE ?</b-dropdown-item>
            <b-dropdown-item to="/Accompagnement">MES ACCOMPAGNEMENTS</b-dropdown-item>
            <b-dropdown-item to="/Contact">ME CONTACTER</b-dropdown-item>
            <b-dropdown-item disabled>SE CONNECTER</b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
    export default {
    name: "Header"
    };
</script>