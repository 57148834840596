import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/Calendly",
    name: "Calendly",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Calendly.vue")
  },
  {
    path: "/Reflexologie",
    name: "Reflexologie",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Reflexologie.vue")
  },
  {
    path: "/Sophrologie",
    name: "Sophrologie",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Sophrologie.vue")
  },
  {
    path: "/Hypnose",
    name: "Hypnose",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Hypnose.vue")
  },
  {
    path: "/Accompagnement",
    name: "Accompagnement",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Accompagnement.vue")
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue")
  },
  {
    path: "/Quisuisje",
    name: "Quisuisje",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Quisuisje.vue")
  },
  {
    path: "/Temoignages",
    name: "Temoignages",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Temoignages.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
