<template>
  <div class="container-fluid">
    <div class="row">
      <div class="navbar">
        <Header />
      </div>
      <div>
        <b-carousel id="carousel-fade" fade :interval="7000" no-hover-pause controls indicators img-width=100% img-height=100% style="text-shadow: 1px 1px 2px #333;">
          <b-carousel-slide img-src="../../src/assets/bg-h03.jpg">
            <div>
              <b-row>
              <b-col>
                <p>Hypnose à domicile</p>
                <b-button class="font-weight-bold" pill variant="outline-light" size="lg" to="/Hypnose">
                  En savoir plus
                </b-button>
              </b-col>
              </b-row>
            </div>
          </b-carousel-slide>
          <b-carousel-slide
            text="Sophrologie ludique"
            img-src="../../src/assets/bg-s02.jpg"
          >
            <b-button 
              class="font-weight-bold"
              pill variant="outline-light"
              size="lg"
              to="/Sophrologie"
            >
              En savoir plus
            </b-button>
          </b-carousel-slide>
          <b-carousel-slide
            text="Reflexologie plantaire"
            img-src="../../src/assets/bg-r02.jpg"
          >
            <b-button 
              class="font-weight-bold"
              pill variant="outline-light"
              size="lg"
              to="/Reflexologie"
            >
              En savoir plus
            </b-button>
          </b-carousel-slide>
        </b-carousel>
      </div>
            <!-- Navbar-bottom -->
       <div class="container-fluid">
        <div class="row">
          <div class="navbar fixed-bottom">
              <!-- Cards -->
              <div class="col-4 text-center">
                  <div class="col-md-12 container_foto text-center">
                    <div class="ver_mas text-center">
                      <span  class="lnr lnr-eye"></span>
                    </div>
                    <router-link to="/Hypnose">
                      <article class="text-left">
                        <h2>Hypnose <br>à domicile</h2>
                        <h4>Découvrir</h4>
                      </article>
                    </router-link>
                    <img class="img-card" src="../../src/assets/card-h03.jpg" alt="">
                  </div>
                </div>
              <div class="col-4">
                <div class="col-md-12 container_foto">
                  <div class="ver_mas text-center">
                      <span id="click" class="lnr lnr-eye"></span>
                  </div>
                  <router-link to="/Sophrologie">
                    <article class="text-left">
                        <h2>Sophrologie <br>ludique</h2>
                        <h4>Découvrir</h4>
                    </article>
                  </router-link>
                  <img src="../../src/assets/card-s05.jpg" alt="">
                </div>
              </div>
              <div class="col-4 ">
                <div class="col-md-12 container_foto">
                  <div class="ver_mas text-center">
                      <span id="click" class="lnr lnr-eye"></span>
                  </div>
                  <router-link to="/Reflexologie">
                    <article class="text-left">
                        <h2>Reflexologie <br>plantaire</h2>
                        <h4>Découvrir</h4>
                    </article>
                  </router-link>
                  <img src="../../src/assets/card-r09.jpg" alt="">
                </div>
              </div>
          <a class="navbar-brand" href="#"></a>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  name: "Home",
  components: {
    Header,
  }
};
</script>
<style>
  .contain-cards{
    height:80% !important;
  }
  .w-100 {
    width: 100% !important;
    height: 100vh;
  }

  h1 {
    color: #FCFBFA;
  }
  .container {
    margin: 20px 20px 20px 20px;
  }

  .container_foto {
    background-color: rgba(57, 62, 93, 0.7);
    padding: 0;
    overflow: hidden;
    max-width: 90%;
    margin: 15px;
  }

  @media only screen and (max-width:900px){
    .container_foto {
      background-color: rgba(57, 62, 93, 0.7);
      padding: 0;
      max-width: 100%;
      margin: 5px;
      align-items: center;
    }
  }
  @media only screen and (max-width:500px){
    .container_foto {
      background-color: rgba(57, 62, 93, 0.7);
      padding: 0;
      max-width: 100%;
      max-height: 100%;
      margin: 0;
      align-items: center;
    }
  }
  .container_foto article {
    padding: 10%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .container_foto h2 {
    color: rgb(255, 255, 255);
    font-weight: 800;
    font-size: 20px;
    border-bottom: rgb(148, 147, 147) solid 1px;
  }

  @media only screen and (max-width:900px){
    .container_foto h2{
      color: rgb(255, 255, 255);
      font-weight: 700;
      font-size: 13px;
      border-bottom: rgb(148, 147, 147) solid 1px;
    }
  }

  @media only screen and (max-width:500px){
    .container_foto h2{
      color: rgb(255, 255, 255);
      font-weight: 700;
      font-size: 10px;
      border-bottom: rgb(148, 147, 147) solid 1px;
    }
    .container_foto article {
      padding: 5%;
      position: absolute;
      bottom: 0;
      z-index: 1;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }

  .container_foto h4 {
    font-weight: 300;
    color: #fff;
    font-size: 13px;
  }

  @media only screen and (max-width:900px){
    .container_foto h4{
      color: rgb(255, 255, 255);
      font-weight: 700;
      font-size: 8px;
      border-bottom: rgb(148, 147, 147) solid 1px;
    }
  }

  .container_foto img {
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: all 4s ease;
    -moz-transition: all 4s ease;
    -o-transition: all 4s ease;
    -ms-transition: all 4s ease;
    transition: all 4s ease;
  }

  .ver_mas {
    background-color: rgba(35, 0, 100, 0.5);;
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    transform: translate(0px, 70px);
    -webkit-transform: translate(0px, 70px);
    -moz-transform: translate(0px, 70px);
    -o-transform: translate(0px, 70px);
    -ms-transform: translate(0px, 70px);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .ver_mas span {
    font-size: 40px;
    color: #fff;
    position: relative;
    margin: 0 auto;
    width: 100%;
    top: 13px;
  }

  /*hovers*/
  .container_foto:hover {
    cursor: pointer;
  }

  .container_foto:hover img {
    opacity: .5;
    transform: scale(2.5);
  }

  .container_foto:hover article {
    transform: translate(2px, -69px);
    -webkit-transform: translate(2px, -30px);
    -moz-transform: translate(2px, -69px);
    -o-transform: translate(2px, -69px);
    -ms-transform: translate(2px, -69px);
  }
  @media only screen and (max-width:900px){
    .container_foto:hover article{
    transform: translate(2px, -69px);
    -webkit-transform: translate(2px, 5px);
    -moz-transform: translate(2px, -69px);
    -o-transform: translate(2px, -69px);
    -ms-transform: translate(2px, -69px);
    }
  }

  .container_foto:hover .ver_mas {
    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    opacity: 1;
  }
  .dropdown-menu {
    z-index:1010; 
    position:relative; 
  }
</style>